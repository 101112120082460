<template>
  <div class="list-users">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <navigator :items="navigate_items"></navigator>
        </div>
        <div class="col-12">
          <div class="card card-shadow">
            <div class="card-body">
              <div class="table-header d-flex justify-content-between">
                <button class="btn btn-primary btn-add" v-on:click="newItem()">
                  <i class="fa fa-plus" aria-hidden="true"></i>Adicionar
                </button>
                <input
                  type="text"
                  placeholder="Pesquisar"
                  v-model="filterTerm"
                  class="form-control"
                  v-on:keyup="filterTable()"
                />
              </div>
              <b-table
                hover
                no-local-sorting
                :fields="fields"
                :items="data"
                :per-page="0"
                :current-page="currentPage"
                @row-clicked="selectItemTable"
                @sort-changed="sortingChanged"
              >
                <template v-slot:cell(created_at)="data">{{formatDate(data.value)}}</template>
                <template v-slot:cell(id)="data">
                  <button
                    class="btn btn-primary float-right btn-destroy-table"
                    v-on:click="destroy(data)"
                  >
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </template>
              </b-table>
              <div class="no-data" v-if="data.length == 0">
                <span>Sem dados</span>
              </div>
              <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helper from "@/imports/Helpers";

export default {
  name: "list-wallets",
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      rows: 0,
      data: [],
      filter: {
        name: ""
      },
      includes: ["wallets"],
      sortBy: "",
      filterTerm: "",
      fields: [
        { key: "name", label: "Nome", class: "name-cell", sortable: true },
        { key: "cpf", label: "CPF", class: "cpf-cell", sortable: false },
        { key: "created_at", label: "Data de Criação", class: "date-cell", sortable: true },
        { key: "id", label: "", class: "remove-cell" },
        
      ],
      navigate_items: [
        {
          text: "Usuários",
          active: true
        }
      ]
    };
  },
  watch: {
    currentPage: {
      handler: function(value) {
        this.providerData();
      }
    }
  },
  mounted() {
    this.providerData();
  },
  methods: {
    providerData() {
      helper
        .getPaginateQuery(
          "users",
          helper.getUrlBuildQuery(
            this.perPage,
            this.currentPage,
            this.filter,
            this.includes,
            this.sortBy
          )
        )
        .then(response => {
          this.rows = response.data.total;
          this.data = response.data.data;
        });
    },
    selectItemTable: function(record, index) {
      this.$router.push({
        name: "dashboard.user",
        params: { id: record.id }
      });
    },
    newItem: function() {
      this.$router.push({ name: "dashboard.user" });
    },
    formatDate: function(date) {
      return helper.formatDateTime(date);
    },
    sortingChanged: function(sortData) {
      this.sortBy = sortData.sortDesc ? `-${sortData.sortBy}` : sortData.sortBy;
      this.currentPage = 1;
      this.providerData();
    },
    filterTable: function() {
      this.filter.name = this.filterTerm;
      this.currentPage = 1;
      this.providerData();
    },
    destroy: function(dataToDelete) {
      helper.destroyDataObject("users", dataToDelete.item).then(() => {
        this.data.splice(dataToDelete.index, 1);
      });
    }
  }
};
</script>
<style lang="scss">
@import "@/styles/general.scss";
.list-users {
  .name-cell {
    width: 60%;
  }
  .cpf-cell {
    width: 20%;
  }
   .date-cell {
    width: 20%;
  }
}
</style>